



.text {
  text-align: start;
  font-family: Montserrat-500;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 266.667% */
  letter-spacing: 1.2px;
  text-transform: uppercase;
}

.text a{
  color: #000;
text-align: center;
font-family: Montserrat-500;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 32px; /* 266.667% */
letter-spacing: 1.2px;
text-transform: uppercase;
text-decoration: none;
}

#main{
  width: 100%;
  background-color:#fff;
  /* position: sticky; */
  /* top: 108px; */
  z-index: 4;
  padding: 10px 0;
  /* border: 1px solid; */
  

}


@media screen and (max-width: 1130px) and (min-width: 700px) {
  #main {
    flex-wrap: wrap;
  }
  .text {
    font-size: 10px !important;
  }
}
@media screen and (max-width: 767.98px) and (min-width: 200px) {
  #main {
    display: none !important;
  }
}
