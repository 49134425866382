.button {
  color: #fff;
  text-align: center;
  font-family: "Montserrat";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1.8px;
  /* border-radius: 8px; */
  background: #000;
  height: 34px;
  padding: 0px 8px;
  border: none;
  margin-top: -3px;
  display: grid;
  place-content: center;
}
.soonHolder {
  font-size: 6.5px;
  letter-spacing: 0.5px;
  text-align: end;
}
.product-card-element-holder {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 1.12px;
}
.crossed {
  color: #9c9c9c;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 1.12px;
  text-decoration: line-through;
}
.textCenter {
  text-align: start !important;
}
.priceInputHolder {
  border: 1px solid #ccc;
  border-radius: 5px;
  max-width: 100px;
  padding: 1px 5px;
}
.descHolder {
  color: #787878!important;
  font-family: "Arial-500"!important;
  font-size: 18px!important;
  font-style: normal!important;
  font-weight: 400!important;
  line-height: 25px!important;
  letter-spacing: 1.12px!important;
  text-align: start!important;
}
.descHolder span{
  color: #000!important;
  font-weight: 500!important;
}

.priceHolder{
  font-family: "Montserrat-400"!important;
  font-size: 28px!important;
  /* font-weight: 300!important; */
  line-height: 32px!important;
  letter-spacing: 1.12px!important;
  text-align: start!important;
}
.priceHolder a {
  color: #000;
  text-decoration: none;
}
.brandHolder{
  color: #000;
  font-family: "Arial-500"!important;
  font-size: 18px!important;
  font-weight: 400!important;
  line-height: 25px!important;
  letter-spacing: 2px!important;
  text-align: start!important;
}
.nameHolder{
  font-family: "Montserrat-600"!important;
  font-size: 28px!important;
  /* font-weight: 300!important; */
  line-height: 32px!important;
  letter-spacing: 1.12px!important;
  text-align: start!important;
}