.scrollP {
  border: 1px solid #e4e4e4;
  padding: 10px 20px;
}

.innerBox {
  border-bottom: 1px solid #e9e9e9;
  margin: 0 0 20px 0;
  gap: 20px;
}

.MyBagFinalMain {
  border: 1px dashed #000;
  background: #fff;
  padding: 38px 30px 30px 10px;
  margin: 10px 0 0 0;
}
.imgHolder {
  width: 50px !important;
  height: 50px;
  object-fit: contain;
}
.MainInner {
  background: #fff;
  padding: 17px 3px;
  height: 600px;
  overflow: auto;
}

.MainInner::-webkit-scrollbar {
  border-radius: 50px;
  background-color: #d9d9d9;
  width: 4px;
  margin: 0 10px 0 0;
  padding: 0 10px 0 0;
}

.MainInner::-webkit-scrollbar-thumb {
  border-radius: 50px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #000000;
  width: 4px;
}

.MyBagFinalTop {
  display: flex;
  justify-content: space-between;
}

.MyBagFinalRight,
.MyBagFinalleft {
  display: flex;
  align-items: center;
  gap: 10px;
}

.MyBagFinalRight h4 {
  color: #000;
  font-family: "Montserrat";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1.6px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  margin: 0;
}

.MyBagFinalRight h4 span {
  font-size: 22px;
  letter-spacing: 2.2px;
}

.MyBagFinalleft h5 {
  color: #000;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}

.MyBagFinalleft h5 b {
  color: #000;
  font-family: "Montserrat";
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
}

.Mainbox {
  display: flex;
  justify-content: space-between;
  /* border-bottom: 1px solid #E9E9E9;; */
  margin: 0 0 26px 0;
  padding: 0 0 20px 0;
  align-items: center;
}

.MainBag h3 {
  color: #000;
  font-family: "Montserrat";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 177.778% */
  letter-spacing: 1.12px;
  text-transform: uppercase;
}

.Mainbox1M {
  display: flex;

  align-items: center;
}

.Mainbox1M img {
  width: 100%;
}

.Mainbox3 h2 {
  color: #000;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px; /* 228.571% */
  letter-spacing: 1.12px;
  margin: 0;
}

.Mainbox1M p {
  margin: 0 0 0 0;
  display: flex;
  gap: 10px;
}
.Mainbox {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e9e9e9;
  margin: 0 0 26px 0;
  padding: 0 0 20px 0;
  align-items: center;
}
.Mainbox1M {
  display: flex;
  gap: 25px;
  align-items: center;
}

.Mainbox1M img {
  width: 100%;
  max-width: 100px;
}

.Mainbox3 h2 {
  color: #000;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 228.571% */
  letter-spacing: 1.12px;
  margin: 0;
}

.Mainbox1M p {
  margin: 0 0 0 0;
  display: flex;
  gap: 10px;
}
.Mainbox2M {
  display: flex;
  align-items: center;
  /* gap: 20px; */
}

.Mainbox5 {
  margin: 0 0 0 20px;
}

.Mainbox2 img {
  width: 100%;
}

.Span11 {
  color: #9c9c9c;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 10px; /* 266.667% */
  letter-spacing: 1.12px;
}

.Span1 {
  color: #9c9c9c;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 266.667% */
  letter-spacing: 1.12px;
  text-decoration: line-through;
}

.Span2 {
  color: #000;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 228.571% */
  letter-spacing: 1.12px;
}

.Mainbox2M {
  display: flex;
  align-items: center;
  /* gap: 20px; */
}

.Mainbox input {
  width: 42px;
  height: 26px;
  text-align: center;
  /* padding: 0; */
  line-height: 5;
}

.Mainbox button {
  height: 26px;
  border: 0.5px solid #000;
  background: #f8fafb;
  width: 22px;
  color: #000;
  font-size: 14px;
  font-weight: 400;
  /* padding: 0; */
}

.TotalPricer {
  display: flex;
  justify-content: space-between;
  padding: 10px 5px 0 5px;
  position: sticky;
  bottom: 0;
  background: #fff;
  flex-direction: column;
}

.TotalPricer h2 {
  color: #000;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px; /* 177.778% */
  letter-spacing: 1.12px;
  margin: 0;
}
.Mainbox5 {
  margin: 0 0 0 20px;
}

.Mainbox2 img {
  width: 100%;
}

.ShipAdress {
  border: 1px solid #e4e4e4;
  background: #fff;
  padding: 15px;
}

.ShipAdress p {
  color: #000;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px; /* 178.571% */
  letter-spacing: 1.12px;
  border-bottom: 1px dashed #000;
  padding: 0 0 8px 0;
}

.ShipAdress2 {
  background-color: #f8f8f8;
  padding: 20px 0 0px 20px;
  margin: 32px 0;
}
.ShipAdress2 textarea::placeholder,
.ShipAdress2 textarea {
  color: #000;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 228.571% */
  letter-spacing: 1.12px;
  width: 100%;
  border: none;
  outline: none;
  background-color: transparent;
}

.dFlex{
  display: flex;
  flex-wrap: wrap;
}
.dFlex > div{
  width: 50%;
}

.ShipAdress2 label {
  color: #000;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 19px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 228.571% */
  letter-spacing: 1.12px;
  width: 100%;
  border: none;
  outline: none;
  background-color: transparent;
  /* text-transform: uppercase; */
}

.ShippControl h2 {
  color: #000;
  font-family: "Montserrat";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 177.778% */
  letter-spacing: 1.12px;
  text-transform: uppercase;
}
.warning {
  text-decoration: underline;
}

.ShipBut {
  width: 100%;
}
.ShipBut button {
  width: 100%;
}
.btnHolder {
  width: 100px;
}
.SupportHolder p{
  color: #000;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 1.12px;
  border: 0;
  padding: 0;
  margin: 0 auto 0 1.5rem;
}
.SupportHolder p .supportLinkHolder{
  margin-left: 10px;
  color: #000;
  text-decoration: underline !important;
}
.ShipBut button,
.btnHolder {
  color: #fff;
  text-align: center;
  font-family: "Montserrat";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 213.333% */
  letter-spacing: 1.5px;
  text-transform: uppercase;
  background-color: #000;
  margin: 0 0 28px 0;
}
@media (max-width: 1300px) {
  .ShipBut button,
  .btnHolder {
    font-size: 13px;
  }
}
@media (max-width: 1199px) {
  .MainInner {
    padding: 17px 5px;
  }
  .scrollP {
    padding: 10px 5px;
  }
  
  .Mainbox1M {
    gap: 15px;
  }
  .ShipBut button,
  .btnHolder {
    font-size: 11px;
  }
}
@media (max-width: 1025px) {
  .dFlex > div{
    width: 100%;
  }
}
