@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700&family=Nunito:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400&family=Open+Sans:wght@300;400;500;600&family=Rubik:wght@300;400;500;600;700;800&display=swap");

.access {
  color: #000;
  font-family: Montserrat-600;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 213.333% */
  letter-spacing: 1.5px;
}
.tab {
  color: #000;
  text-align: center;
  font-family: "Montserrat-600";
  font-size: 15px;
  font-style: normal;
  line-height: 32px; /* 213.333% */
  letter-spacing: 1.5px;
  text-transform: uppercase;
}
.hr{
  color: black;
  opacity: 1;
}
.tabNotActive {
  color: #bcbcbc;
  text-align: center;
  font-family: Montserrat;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 213.333% */
  letter-spacing: 1.5px;
  text-transform: uppercase;
}
.label {
  color: #000;
  font-family: Montserrat-500;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}
.input {
  border-bottom: 1px solid #d9d9d9;
}
.remember {
  color: #000;
  font-family: Montserrat-500;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 246.154% */
  letter-spacing: 1.3px;
}
.loginBtn {
  color: #fff;
  text-align: center;
  font-family: Montserrat-600;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 213.333% */
  letter-spacing: 1.5px;
  text-transform: uppercase;
  background-color: black;
}

.closeButton {
  margin-top: 20px;
  /* margin-top: 10px; */
  padding: 8px 16px;
  background: #000;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  color: #fff;
  font-family: "Montserrat-600";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1.4px;
}
.termsLine {
  color: #403a39;
  text-align: center;
  font-family: Arial-400;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 228.571% */
  letter-spacing: 1.12px;
}
.privacy {
  color: #403a39;
  font-family: Arial-500;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 1.12px;
  text-decoration-line: underline;
}

.rememInput input[type="checkbox"]:checked {
  accent-color: #000;
}

/* new */

.LoginMain {
  max-width: 450px;
  margin: 70px auto 0 auto;
}

.LoginMain h4 {
  color: #000;
  font-family: "Montserrat";
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 213.333% */
  letter-spacing: 1.5px;
  margin-bottom: 20px;
}

.EmailDiv {
  display: flex;
  gap: 15px;
  align-items: center;
}
.SvgEmail {
  width: 40px;
  height: 40px;
}

.SvgEmail svg {
  width: 40px;
  height: 40px;
}

.LabelEmail input {
  border-bottom: 1px solid #d9d9d9;
  outline: none;
  margin-top: 5px;
  width: 100%;
}

.LabelEmail label {
  color: #000;
  font-family: "Montserrat-500";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

.LabelEmail {
  width: 100%;
}

.passwardDiv {
  margin: 30px 0 0 0;
}

.ReCheck {
  display: flex;
  justify-content: space-between;
  margin: 30px 0 0 0;
}

.RememMe {
  display: flex;
  gap: 10px;
  color: #000;
  font-family: "Montserrat-500";
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 246.154% */
  letter-spacing: 1.3px;
}

.Forget {
  color: #000;
  font-family: "Montserrat-500";
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 246.154% */
  letter-spacing: 1.3px;
  text-decoration-line: underline;
  text-underline-offset: 3px;
}

.ButtonLogin {
  margin: 20px 0 32px 0;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
}

.ButtonLogin a {
  color: #fff;
  text-align: center;
  font-family: "Montserrat";
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 213.333% */
  letter-spacing: 1.5px;
  text-transform: uppercase;
}

.SignUpW p {
  text-align: center;
  color: #000;
  font-family: "Montserrat";
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 246.154% */
  letter-spacing: 1.3px;
  text-decoration-line: underline;
  text-underline-offset: 3px;
  margin: 0 0 50px 0;
}

.SignUpW p span {
  font-weight: 700;
}

.PolicyA p {
  color: #403a39;
  text-align: center;
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 228.571% */
  letter-spacing: 1.12px;
}

.PolicyA p span {
  font-weight: 500;
  text-decoration-line: underline;
  text-underline-offset: 3px;
}
@media only screen and (max-width: 480px) {
  .ReCheck {
    flex-wrap: wrap;
  }
}
