@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700&family=Nunito:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400&family=Open+Sans:wght@300;400;500;600&family=Rubik:wght@300;400;500;600;700;800&display=swap");

/* MOdalRadio */
.ModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1021;
}
/* ModalRadio End */

.BrandName {
  height: 60px;
  border: 0.5px solid #d5d9d9;
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 8px;
  padding: 0 18px;
}

.BrandName label {
  color: #000;
  font-family: "Montserrat";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 228.571% */
  letter-spacing: 1.4px;
  display: flex;
  /* gap: 10px; */
  text-align: left;
  align-items: center;
  /* padding: 0 18px; */
}

.ModalTop {
  width: 60%;
  margin: auto;
  border-radius: 8px;
  border: 1px solid #d5d9d9;
  background: #fff;
  /* border: 1px solid #000; */
  padding: 30px 49px 49px 49px;
}

.ModalTop h1 {
  color: #000;
  font-family: "Montserrat";
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 2.2px;
  text-align: center;
  margin-bottom: 10px;
}

.BrandInRadio {
  border-radius: 8px;
  /* border: 1px solid #d5d9d9; */
  /* background: #f8fafb; */
  /* padding: 27px; */
}

.CaseReason{
text-align: start !important;
}

.BrandName input[type="radio"],
.title input[type="radio"]:checked {
  width: 25px;
  height: 25px;
  top: 30%;
}

.BrandName input[type="radio"] {
  padding: 0;
  border: 5px solid #000000;
  margin-right: 16px;
}

.BrandName input[type="radio"]:checked {
  border: 8px solid #003b5f;
}

.BrandName.borderRad {
  border-radius: 20px 20px 0px 0px;
}
.active {
  color: #000;
}
.BrandName .active {
  color: #000 !important;
}

.BrandName input[type="radio"]:checked:after,
.BrandName input[type="radio"]:not(:checked):before {
  width: 25px;
  height: 25px;
  border-radius: 15px;
  background-color: #fff;
  content: "";
  display: inline-block;
  visibility: visible;
}

.BrandName input[type="radio"]:checked:after {
  border: 8px solid #000;
  transition: 0.5s;
}
.BrandName input[type="radio"]:not(:checked):before {
  border: 1px solid #8c8c8c;
  transition: 0.5s;
}

.BrandButton {
  display: flex;
  justify-content: end;
  gap: 20px;
  margin: 28px 0 0 0;
}

.BrandButton button {
  border: 1px solid #000;
  height: 42px;
  text-align: center;
  font-family: "Montserrat";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 33px; /* 206.25% */
  letter-spacing: 1.6px;
  text-transform: uppercase;
  padding: 0 24px;
}

.BrandButton .Button1 {
  background: #fff;
  color: #000;
}
.BrandButton .Button2 {
  background: #000;
  color: #fff;
}

.ModalResponsive {
  display: grid;
  grid-template-columns: repeat(3, auto);
  gap: 20px;
}

.option {
  max-width: 50ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #000;
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1.12px;
}
.input {
  border-radius: 8px;
  border: 1px solid #d5d9d9;
  padding: 15px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  outline: none;
}
.input::placeholder {
  color: #9da1ac;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1.6px;
}
.labelAmountDiv{
  border-radius: 8px;
border: 1px solid #D5D9D9;
margin-top: 10px;
margin-bottom: 10px;
padding: 15px;
display: flex;
justify-content: start;
align-items: center;
}
@media (max-width: 1299px) {
  .ModalTop {
    width: 80%;
  }
}
.select {
  -webkit-appearance: none;
  outline: none;
  padding: 15px;
  border-radius: 8px;
  border: 1px solid #9c9c9c;
  width: 100%;
  /* height: 60px; */
}
.select::placeholder {
  color: #9c9c9c;
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1.12px;
}
.label {
  color: #9c9c9c;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1.6px;
}
.labelInput {
  border-radius: 7px;
  background: #d5d9d9;
  color: #000;
  text-align: center;
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1.12px;
  width:30%;
  margin: 0px;
  padding: 3px 6px;

}

.selectDiv select{
 transition: height 0.4s ease;;
 transition: all 0.4s ease;
}

.ModalLast section {
  /* background-color: #000000; */
  /* padding: 30px 30px ; */
  /* height: 80vh;
  overflow: auto; */
}


/* .Tdelay{

} */
.delaycontent{
  -webkit-transition: max-height 3s; 
  -moz-transition: max-height 3s; 
  -ms-transition: max-height 3s; 
  -o-transition: max-height 3s; 
  transition: max-height 3s;  
  }


  .fadeInUp{
    /* max-height: 80vh;
    overflow: auto; */
  }

.fadeInUp

{animation:fadeInUp 1s ease backwards;}
@keyframes fadeInUp{
  0%{transform:translate(0px, 00px); opacity: 0;}
  100%{transform:translate(0px, 0); opacity: 1;}
}


@media (max-width: 900px) {
  .ModalResponsive {
    grid-template-columns: repeat(2, auto);
  }
}

@media (max-width: 650px) {
  .ModalResponsive {
    grid-template-columns: repeat(1, auto);
    height: 250px;
    overflow: auto;
  }
}
