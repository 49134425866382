.reasonContainer {
}
.iconHolder {
  height: 40px;
  width: auto;
}
.flexBox {
  display: flex;
}
.reasonCard {
  height: 60px;
  border: 0.5px solid #d5d9d9;
  /* align-items: center; */
  background-color: #fff;
  border-radius: 8px;
  padding: 10px 18px;
  position: relative;
  cursor: pointer;
  height: auto;
}
.reasonHolder {
  margin: 20px 0;
  display: grid;
  grid-template-columns: repeat(5, auto);
  gap: 20px;
}
.activeReason {
  border: 1px dashed #3c74b4;
  position: relative;
  color: #3c74b4;
}
.shake {
  animation: shake 0.5s;
}

@keyframes shake {
  0% {
    transform: translate(0, 0);
  }
  20% {
    transform: translate(5px, 0);
  }
  40% {
    transform: translate(0, 5px);
  }
  60% {
    transform: translate(-5px, 0);
  }
  80% {
    transform: translate(0, -5px);
  }
  100% {
    transform: translate(0, 0);
  }
}
.activeReason::after {
  content: "";
  width: 14px;
  height: 8px;
  border-bottom: solid 2px #3c74b4;
  border-left: solid 2px #3c74b4;
  transform: rotate(-45deg);
  position: absolute;
  right: 3%;
  top: 10%;
}
.reasonTitle {
  color: #000;
  text-align: left;
  font-family: Montserrat;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 2.2px;
  text-transform: uppercase;
  margin-top: 25px;
}
.textHolder {
  margin-left: 5px;
  margin-bottom: 0;
  line-height: 18px;
}
.descHolder {
  margin: 5px 5px 0 0;
  font-size: 11px;
}
@media (max-width: 1300.98px) {
  .reasonTitle {
    font-size: 18px;
  }
}
@media (max-width: 1024.98px) {
  .reasonHolder{
    grid-template-columns: repeat(3,auto);
  }
}
