.holder{
    display: flex;
    position: fixed;
    bottom: 1%;
    right: 1%;
    gap: 1rem;
    z-index: 1024;
}
.button{
    background-color: #000;
    color: #fff;
    font-family: Montserrat-600;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    text-transform: uppercase;
    line-height: normal;
    height: 40px;
    width: 150px;
    margin: auto;
    display: grid;
    place-content: center;
}
.button:hover{
    background-color: #fff;
    color: #000;
    border: 1px solid #000;
}