/* Form Container */
.create-newsletter .form-container {
  padding: 5px;
  border-radius: 12px;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(8.5px);
  -webkit-backdrop-filter: blur(8.5px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  width: 100%;
}

/* Progress Bar */
.create-newsletter .progress-bar {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.create-newsletter .progress-step {
  width: 30px;
  height: 30px;
  background-color: #555;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.create-newsletter .progress-step.active {
  background-color: #00bcd4;
}

.create-newsletter .progress-line {
  flex: 1;
  height: 4px;
  background-color: #555;
  margin: 0 10px;
  transition: background-color 0.3s ease;
}

.create-newsletter .progress-line.active {
  background-color: #00bcd4;
}

/* Accordion Styles */
.create-newsletter .accordion-item {
  margin-bottom: 10px;
  border-radius: 8px;
  overflow: hidden;
  /* background-color: #1e1e1e; */
  /* background-color: #2c2c2c; */
  transition: transform 0.3s ease;
  position: relative;
}

.create-newsletter .accordion-item:hover {
  transform: translateY(-2px);
}

.create-newsletter .multi-step-form {
}
.create-newsletter .accordion-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border: 1px solid #000;
  border-radius: 10px;
  font-family: Arial;
  text-transform: uppercase;
}

.create-newsletter .accordion-header.active {
  background: linear-gradient(90deg, rgb(255, 255, 255) 0%, rgb(0, 0, 0) 100%);
  color: #fff;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: 0;
}
.create-newsletter .accordion-header.active h3{
  color: #000;
}
.create-newsletter .accordion-header h3 {
  font-size: 18px;
  margin: 0;
}

.create-newsletter .accordion-header span {
  font-size: 24px;
  transition: transform 0.3s ease;
}

.create-newsletter .accordion-header.active span {
  transform: rotate(180deg);
}

.create-newsletter .accordion-body {
  padding: 10px;
  animation: fadeIn 0.5s ease-in-out;
  border: 1px solid #000;
  border-radius: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-color: f0f0f0;
  max-height: 350px;
  min-height: 300px;
  overflow-y: auto;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Form Labels and Inputs */
.create-newsletter .accordion-body label {
  display: block;
  margin-bottom: 20px;
  /* font-size: 16px; */
  /* color: #ddd; */
}

.create-newsletter .accordion-body input,.create-newsletter .accordion-body select {
  width: 100%;
  padding: 12px 15px;
  margin-top: 8px;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  transition: background-color 0.3s ease, color 0.3s ease;
  border: 1px solid #ccc;
  color: #000;
}

.create-newsletter .accordion-body input:focus,.create-newsletter .accordion-body select:focus {
  outline: none;
  /* background-color: #444; */
}

/* Button Styles */
.create-newsletter .button-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  background: #fff;
  font-family: Arial;
  text-transform: uppercase;
  position: sticky;
  bottom: 0;
  padding: 10px 0;
}

.create-newsletter .next-btn,
.create-newsletter .prev-btn,
.create-newsletter .submit-btn {
  padding: 0.5rem 2rem;
  border: 1px solid #000;
  background: #000;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  text-decoration: none;
  color: #fff;
  /* width: 150px; */
}

.create-newsletter .prev-btn:hover {
  color: #000;
  border: 1px solid #000;
  background: #fff;
}
#newsletterDateSelector .react-datepicker-wrapper {
  border: 1px solid #ccc;
  width: 100%;
  border-radius: 5px;
  margin-top: 8px;
}
.create-newsletter .next-btn:active,
.create-newsletter .prev-btn:active,
.create-newsletter .submit-btn:active {
  transform: scale(0.98);
}

/* Responsive Design */
@media (max-width: 600px) {
  .create-newsletter .button-group {
    flex-direction: column;
  }

  .create-newsletter .next-btn,
  .create-newsletter .prev-btn,
  .create-newsletter .submit-btn {
    width: 100%;
    margin-bottom: 10px;
  }

  .create-newsletter .submit-btn {
    margin-bottom: 0;
  }
}
