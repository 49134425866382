.video-container {
    position: relative;
    width: 100%;
    max-width: 750px;
    margin: auto;
    background: #000;
    border-radius: 12px;
    overflow: hidden;
}

.video {
    width: 100%;
    height: auto;
    display: block;
}

.custom-controls {
    position: absolute;
    bottom: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.7);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    box-sizing: border-box;
}

.custom-controls button {
    background: none;
    border: none;
    color: #fff;
    font-size: 24px;
    cursor: pointer;
    transition: color 0.3s;
}

.custom-controls button:hover {
    color: #ddd;
}

.progress-container {
    flex: 1;
    height: 5px;
    background: #555;
    cursor: pointer;
    position: relative;
    margin: 0 10px;
}

.progress-bar {
    height: 100%;
    width: 0;
    background: #e74c3c;
    position: absolute;
    top: 0;
    left: 0;
}

.time {
    font-size: 14px;
}
