.dGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, 19%);
  justify-content: space-between;
  gap: 2.5rem 0.5rem;
  transition: all 0.25s;
  margin: 3rem auto;
  overflow-x: hidden;
}
.modalButton {
  color: #fff;
  text-align: center;
  font-family: "Montserrat";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1.8px;
  /* border-radius: 8px; */
  background: #000;
  height: 34px;
  width: max-content;
  padding: 0px 8px;
  border: none;
  margin-top: -3px;
  display: grid;
  place-content: center;
}
.cardElement {
  border: 1px solid #e1e1e1;
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  padding: 25px 10px;
  border-radius: 10px;
  margin: auto;
  position: relative;
  transition: box-shadow 0.3s;
}
.cardElement:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2), 0 0 20px rgba(0, 0, 0, 0.1);
  /* transform: translateY(-2px); */
}
.salesHolder {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 25px;
  padding: 2px;
  width: 35px;
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.salesIcon {
  animation: rotate 4s linear infinite;
}
.imgHolder {
  width: 175px;
  height: 175px;
  object-fit: contain;
  cursor: pointer;
}
.brandHolder {
  margin-top: 1rem;
  font-family: Montserrat-500;
  font-size: 22px;
  line-height: 25px;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
}
.titleHolder {
  font-family: Arial;
  font-size: 16px;
  line-height: 20px;
  text-transform: capitalize;
  color: #6a6a6a;
  cursor: pointer;
  text-align: center;
}
.priceHolder {
  font-family: Montserrat-500;
  font-size: 24px;
  /* line-height: 15px; */
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}
.priceCrossed {
  color: #ccc;
  /* line-height: 15px; */
  text-decoration: line-through;
  font-size: 14px;
  margin: 0;
}
.priceHolder a {
  color: #000;
  text-decoration: none;
}
.btnHolder {
  background-color: #000;
  color: #fff;
  font-family: Montserrat-600;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  text-transform: uppercase;
  line-height: normal;
  height: 40px;
  width: 100%;
  padding: 5px;
  margin: auto;
  display: grid;
  place-content: center;
}
.soonHolder {
  font-size: 6.5px;
  letter-spacing: 0.5px;
  text-align: end;
}

.HoverArrow svg {
  transition: 0.5s;
  width: 20px;
  height: 20px;
}

.HoverArrow:hover svg {
  /* margin-left: 10px; */
  transition: 0.5s;
}

/* animation */
.ImgHover img {
  width: 100%;
  height: 300px;
}
.ImgHover1 {
  position: relative;
  margin: 0;
  overflow: hidden;
  padding: 0;
  transition: 0.75s;
  cursor: pointer;
}
.ImgHover1::before {
  position: absolute;
  top: 0;
  left: -75%;
  z-index: 2;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
  background: -webkit-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 100%
  );
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(240, 240, 240, 0.3) 100%
  );
  transform: skewX(-25deg);
}
.ImgHover1::before {
  -webkit-animation: shine 0.75s;
  animation: shine 0.75s;
}
@-webkit-keyframes shine {
  100% {
    left: 125%;
  }
}
@keyframes shine {
  100% {
    left: 125%;
  }
}
@media (max-width: 1199px) {
  .dGrid {
    grid-template-columns: repeat(auto-fill, 24%);
  }
}
@media (max-width: 900px) {

  .dGrid {
    grid-template-columns: repeat(auto-fill, 30%);
  }
}