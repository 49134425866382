.container {
  width: 60%;
  margin: 2rem auto;
}
.formContainer {
  border-radius: 8px;
  border: 1px solid #d5d9d9;
  background: #f8fafb;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  padding: 2rem;
}
.containerTitle {
  color: #000;
  font-family: Montserrat;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 2.2px;
  margin-bottom: 2rem;
  text-align: center;
}

.labelHolder {
  border-radius: 8px;
  border: 1px solid #d5d9d9;
  background: #fff;
  width: 100%;
  display: flex;
  color: #9c9c9c;
  padding: 0.5rem 1rem;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1.6px;
  flex-direction: column;
  gap: 4px;
  text-transform: capitalize;
}
.labelHolder .formControl{
  display: block;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}
.labelHolder .formControl:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #ddd;
  outline: 0;
  box-shadow: 0 0 0 .25rem rgb(62 64 67 / 11%);
}
.submitButton {
  padding: 0.5rem 2rem;
  border: 1px solid #fff;
  background: #000;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  text-decoration: none;
  color: #fff;
}
.settingContainer {
  font-family: Montserrat-500;
  font-weight: 500;
}
.accordian {
  background: #fff;
  border-bottom: 1px solid #fff;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.accordingHolder {
  width: 100%;
  height: 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #d3d3cd91;
  border-radius: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
}
.accordingHolder p {
  margin: 0 auto;
  cursor: pointer;
}
.accordingHolder .active {
  margin: 0;
  border-radius: 10px;
  background: #615f5f;
  color: #fff;
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}
