.inorderflex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px dashed #000;
    padding: 0px 0px 20px 0px;
    margin: 27px 0 0 0;
  }
  .inorderflex h2 {
    color: #000;
    font-family: "Montserrat";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 2px;
    margin: 0;
  }