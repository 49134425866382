.ArriavalsInnerContent {
  height: 555px;
  width: 100%;
  background-color: #f3f4f6;
  padding: 30px 30px 10px 30px;
}
.BnadLogo {
  align-items: center;
  border-radius: 25px;
  display: flex;
  height: 407px;
  justify-content: center;
  margin-bottom: 15px;
  width: 407px;
}
.titleWithLogo {
  font-family: Arial;
  font-size: 20px;
  font-weight: 800;
  text-align: left;
  text-transform: uppercase;
}
.autoHeight {
  height: 310px;
  margin: 1rem 0.5rem 0 0;
  overflow: auto;
  padding: 0 5px 0 0;
}
.ArriavalsInnerContent a {
  color: #000;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 1.12px;
  line-height: 24px;
  text-decoration-line: underline;
  text-underline-offset: 3px;
  display: flex;
}
.ArriavalsInnerContent .fitContent {
  margin: auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.owl-carousel .owl-item img {
  display: block;
  width: 100%;
}
.BnadLogo img {
  border-radius: 15px;
  max-height: 100%;
  max-width: 100%;
}
.titleWithLogo {
  font-family: Arial;
  font-size: 20px;
  font-weight: 800;
  text-align: left;
  text-transform: uppercase;
}
.borderRight {
  border-right: 2px solid #6b6b6b;
}
p.seti {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 1.12px;
  line-height: 40px;
  margin-top: 25px;
  margin-bottom: 1rem;
}
.brandTitleHolder{
  color: #000;
  font-family: Montserrat;
  line-height: 32px;
  font-size: 32px;
  text-transform: uppercase;
  font-weight: bold;
}
.ArriavalsInnerContent h4,
.NewArriavalsList h3 {
  color: #000;
  font-family: Montserrat;
  line-height: 32px;
  text-transform: uppercase;
}
.NewArriavalsList h3 {
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 2.2px;
  margin-bottom: 50px;
  text-align: center;
}
.TopProducts .ArriavalsInnerContent h4 {
  font-size: 20px;
  font-weight: 800;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ArriavalsInnerContent h4 {
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 1px;
  margin: 0 0 20px;
}
.ArriavalsInnerContent h4,
.NewArriavalsList h3 {
  color: #000;
  font-family: Montserrat;
  line-height: 32px;
  text-transform: uppercase;
}
.TopProducts .ArriavalsInnerContent p {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  min-height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ArriavalsInnerContent p,
.TopProducts .ArriavalsInnerContent p {
  color: #000;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  letter-spacing: 1.12px;
  line-height: 24px;
}
.NewArriavalsList .owl-theme .owl-nav,
.OurRetailer .owl-theme .owl-nav {
  -webkit-tap-highlight-color: transparent;
  margin-top: 30px;
  text-align: end;
  width: 100%;
}
.NewArriavalsList button svg {
  /* display: flex!important;
    justify-content: center!important;
    align-items: center!important; */
  margin: auto !important;
}
.NewArriavalsList button {
  background: #eef0f2 !important;
  border-radius: 100px !important;
  color: #7f7f7f !important;
  height: 67px !important;
  margin: 0 0 0 20px !important;
  transition: 0.2s !important;
  width: 67px !important;
}

@media (max-width: 1499.98px) {
  .ArriavalsInnerContent h4 {
    font-size: 24px;
  }
}
