.pagination-container {
  display: flex;
  list-style-type: none;
  /* width: 30%; */
  /* float: right; */
  margin-top: 20px;
  justify-content: center;
}
.pagination-container .pagination-item {
  padding: 0 12px;
  width: 36px;
  height: 36px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid #000;

  background: #fff;
  text-align: center;
  margin: auto 4px;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  box-sizing: border-box;
  align-items: center;
  letter-spacing: 0.01071em;
  line-height: 1.43;
  font-size: 13px;
  min-width: 32px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  display: grid;
  place-content: center;
}
.pagination-container .pagination-item.dots:hover {
  background-color: transparent;
  cursor: default;
}
.pagination-container .pagination-item:hover {
  background-color: rgba(0, 0, 0, 0.04);
  cursor: pointer;
}
.pagination-container .pagination-item.selected {
  background: #000;
  color: #fff;
}
.pagination-container .pagination-item .arrow::before {
  position: relative;
  /* top: 3pt;
    Uncomment this to lower the icons as requested in comments*/
  content: "";
  /* By using an em scale, the arrows will size with the font */
  display: inline-block;
  width: 0.4em;
  height: 0.4em;
  border-right: 0.12em solid rgba(0, 0, 0, 0.87);
  border-top: 0.12em solid rgba(0, 0, 0, 0.87);
}
.pagination-container .pagination-item .arrow.left {
  transform: rotate(-135deg) translate(-50%);
}
.pagination-container .pagination-item .arrow.right {
  transform: rotate(45deg);
}
.pagination-container .pagination-item.disabled {
  pointer-events: none;
}
.pagination-container .pagination-item.disabled .arrow::before {
  border-right: 0.12em solid rgba(0, 0, 0, 0.43);
  border-top: 0.12em solid rgba(0, 0, 0, 0.43);
}
.pagination-container .pagination-item.disabled:hover {
  background-color: transparent;
  cursor: default;
}
