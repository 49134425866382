.search-bar{
   
    position: relative;
}
/* Dropdown container styling */
.dropdown-search {
    position: absolute;
    right: -10px;
    top: 40px;
    min-width: 250px;
    max-height: 300px;
    z-index: 999;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 6px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    transition: all 0.3s ease;
    padding: 0;
}

/* Compact list item styling */
.dropdown-search li {
    display: flex;
    /* align-items: center; */
    gap: 5px;
    padding: 8px 12px;
    border-bottom: 1px solid #f0f0f0;
    transition: background-color 0.2s ease;

}

.dropdown-search li:last-child {
    border-bottom: none; /* Remove border from the last item */
}

.dropdown-search li:hover {
    background-color: #f9f9f9; /* Light hover effect */
    cursor: pointer;
}

/* Container for the entire suggestion item */
.suggestion-item {
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #e0e0e0;
    transition: background-color 0.2s;
}

.suggestion-item:hover {
    background-color: #f9f9f9;
    cursor: pointer;
    border-radius: 5px;
}

/* Container for image */
.suggested-images {
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    flex-shrink: 0;
}

/* Styling for images in suggestion */
.search-logo {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    border-radius: 5px;
}

/* Content container */
.suggested-content {
    display: flex;
    flex-direction: column;
}

/* Main text for name */
.api-name {
    font-size: 14px;
    font-weight: 600;
    color: #333;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
}

/* Secondary text for label */
.suggestion-name {
    font-size: 12px;
    color: #555;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.5px;
    margin-top: 2px;
    text-align: left;
}
