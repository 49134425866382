.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffffa1;;
}

.titleHolder {
  position: relative;
  text-align: center;
  z-index: 3;
  color: #000000;
  margin-top: 120px;
  font-size: 21px;
  padding: 0 10%;
}

.titleHolder strong {
  font-weight: 900;
}

.ballContainer {
  width: 450px;
  margin: 0 auto; 
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -225px;
}


.ball {
  width: 42px;
  height: 42px;
  background-color: #000000;
  position: absolute;
  border-radius: 100%;
  top: 50%;
  margin-top: -21px;
  z-index: 111;
  &.mb {
    animation: motion-blur 1.25s cubic-bezier(0.5,0,0.15,1) infinite;
  }
}

@keyframes motion-blur {
  0%
  {
    left: 0;
    box-shadow: 0 0 0 rgba(255,255,255,0);
  }
  5%
  {
    left: 0;
    transform-origin: 0 0;
    transform: scaleX(0.85);
  }
  25%
  {
    box-shadow: -10px 0 0px 0px rgba(255,255,255, 0.3), -20px 0 0px 0px rgba(255,255,255, 0.2), -33px 0 0px -1px rgba(255,255,255, 0.15), -45px 0 0px -2px rgba(255,255,255, 0.12);
    transform: scaleX(1.05) skewX(-3deg);
  }
  50%
  {
    left: 450px;
    box-shadow: 0 0 0 rgba(255,255,255,0);
    transform: scaleX(1) skewX(0deg);
  }
  51%
  {
    left: 450px;
    transform-origin: 100% 0;
    transform: scaleX(0.9);
  }
  75%
  {
    box-shadow: 10px 0 0px 0px rgba(255,255,255, 0.3), 20px 0 0px 0px rgba(255,255,255, 0.2), 33px 0 0px -1px rgba(255,255,255, 0.15), 45px 0 0px -2px rgba(255,255,255, 0.12);
    transform: scaleX(1.05) skewX(3deg);
  }
	100%
  {
    left: 0px;
    box-shadow: 0 0 0 rgba(255,255,255,0);
    transform: scaleX(1) skewX(0deg);
  }
}